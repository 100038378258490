import { reject } from "lodash";
import path from "path";

import type { NextApiRequest } from "next";
import type { SWRResponse } from "swr";

export function btoa(utf8Str: string) {
  if (isBrowser()) {
    return window.btoa(utf8Str);
  }

  return Buffer.from(utf8Str, "utf-8").toString("base64");
}

export function fileToBase64(file: File) {
  return new Promise((resolve, _) => {
    try {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(String(reader.result).replace(`data:${file.type};base64,`, ""));
      };
      reader.readAsDataURL(file);
    } catch (error: any) {
      console.error(error);
      reject(error);
    }
  });
}

export function formatAsUSD(amountInDollars: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amountInDollars);
}

export function formatAsLocalCurrency({
  amountInCurrency,
  currency,
}: {
  amountInCurrency: number;
  currency: string;
}) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amountInCurrency);
}

export function formatAsPercent({
  fractionDigits = 2,
  numericPercent, // e.g., 1.00 / 50.00 / 100.00
}: {
  fractionDigits?: number;
  numericPercent: number;
}) {
  // Ex: 55 => "55.00%"
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(numericPercent / 100);
}

export function getFilePath(fileName: string) {
  return path.join(
    process.cwd(),
    "_files",
    fileName.split(".").at(-1),
    fileName
  );
}

export function getRequestOrigin(req: NextApiRequest) {
  return new URL(req.headers.referer).origin;
}

export function isBrowser() {
  return typeof window !== "undefined";
}

export function isSWRLoading(swrResponse: SWRResponse) {
  const { data, error } = swrResponse;
  return data === undefined && !error;
}
