import { useCallback } from "react";

import { useClerk } from "@clerk/clerk-react";

export function useSignOut() {
  const clerk = useClerk();
  const signOut = useCallback(async () => {
    await clerk.signOut();
    window.location.assign("/");
  }, [clerk]);

  return { signOut };
}
