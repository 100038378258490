import "../styles/globals.css";
import "core-js/features/string/replace-all";
import "core-js/features/array/at";

import { ChakraProvider } from "@chakra-ui/react";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/nextjs";
import * as Sentry from "@sentry/react";
import Head from "next/head";
import { SWRConfig } from "swr";

import { Analytics } from "@/components/Analytics";
import { ErrorFallback } from "@/components/ErrorFallback";
import { AccountProvider } from "@/contexts/AccountProvider";
import { UserProfileProvider } from "@/contexts/UserProfileProvider";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import * as fetchJSON from "@/lib/fetchJSON";

import type { NextPage } from "next";
import type { AppProps } from "next/app";
import type { ReactElement, ReactNode } from "react";

/* For typescript to accept window.$debugger */
declare global {
  interface Window {
    $debugger: any;
  }
}

type NextPageWithAuth = NextPage & {
  requiresAuth?: boolean;
};

type NextPageWithLayout = NextPageWithAuth & {
  getLayout?: (page: ReactElement) => ReactNode;
  pageHeader: ReactElement;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const isProtectedPage = Component.requiresAuth;

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    (isProtectedPage
      ? (page) => (
          <DashboardLayout pageHeader={Component.pageHeader}>
            {page}
          </DashboardLayout>
        )
      : (page) => <>{page}</>);

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("component", "MyApp");
      }}
      fallback={ErrorFallback}
    >
      <ChakraProvider>
        <Head>
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>
        <ClerkProvider>
          <SWRConfig value={{ fetcher: fetchJSON.fetcher }}>
            <Analytics />
            {isProtectedPage ? (
              <>
                <SignedIn>
                  <UserProfileProvider>
                    <AccountProvider>
                      {getLayout(<Component {...pageProps} />)}
                    </AccountProvider>
                  </UserProfileProvider>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            ) : (
              getLayout(<Component {...pageProps} />)
            )}
          </SWRConfig>
        </ClerkProvider>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  );
}

export default MyApp;
