import segmentPlugin from "@analytics/segment";
import Analytics from "analytics";

Analytics({
  app: "level-debt-app",
  plugins: [
    segmentPlugin({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    }),
  ],
});

export function track(event: unknown, data: unknown = undefined) {
  if (window.analytics && window.analytics.track) {
    window.analytics.track(event, data);
  }
}

export function page(name: string) {
  if (window.analytics && window.analytics.page) {
    window.analytics.page(name);
  }
}

export function identify(id: string) {
  if (window.analytics && window.analytics.identify) {
    window.analytics.identify(id, {
      email: id,
    });
  }
}
