import { Button, Center, Heading, Link, Text, VStack } from "@chakra-ui/react";

export function ErrorFallback() {
  return (
    <Center h="100vh" w="full">
      <VStack spacing={6}>
        <Heading as="h2" size="lg">
          Uh oh!
        </Heading>
        <Heading as="h3" color="GrayText" size="md">
          Looks like something went wrong.
        </Heading>
        <Text color="GrayText" fontWeight="semibold">
          Please try{" "}
          <Button
            color="Highlight"
            onClick={() => {
              window.location.reload();
            }}
            variant="link"
          >
            refreshing
          </Button>{" "}
          the app
        </Text>

        <Text color="GrayText" fontSize="sm" fontWeight="semibold">
          We have been notified, but if you need urgent help, contact{" "}
          <Link
            color="Highlight"
            href="mailto:support@trylevel.app"
            rel="noopener noreferrer"
            target="_blank"
          >
            support@trylevel.app
          </Link>
        </Text>
      </VStack>
    </Center>
  );
}
