import { createContext } from "react";

import { Box, Flex, Heading, Spinner } from "@chakra-ui/react";
import useSWR from "swr";

import type { PropsWithChildren } from "react";
import type { UserProfile } from "types";

const UserProfileContext = createContext<UserProfile>(null);

export function UserProfileProvider(
  props: PropsWithChildren<Record<never, any>>
) {
  const { data: userProfile } = useSWR<UserProfile>("/api/user_profile");

  if (!userProfile) {
    return (
      <Flex w="100vw" h="100vh" justify="center" align="center">
        <Box>
          <Flex align="center" justify="center" direction="column">
            <Heading mb="10">Welcome to Level</Heading>
            <Spinner size="xl" />
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <UserProfileContext.Provider value={userProfile}>
      {props.children}
    </UserProfileContext.Provider>
  );
}
