import { useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import { identify } from "@/lib/analytics";

export function Analytics() {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      console.log("[Level] identified user");
      identify(user.primaryEmailAddress.emailAddress);
    }
  }, [user]);

  return <>{null}</>;
}
