import { createContext } from "react";

import useSWR from "swr";

import type { PropsWithChildren } from "react";
import type { AccountDTO } from "types";

export const AccountContext = createContext<AccountDTO>(null);

export function AccountProvider(props: PropsWithChildren<Record<never, any>>) {
  const { data, error } = useSWR<AccountDTO>("/api/accounts");

  return data === undefined && !error ? null : (
    <AccountContext.Provider value={data}>
      {props.children}
    </AccountContext.Provider>
  );
}
