import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { FaUserCircle } from "react-icons/fa";
import { HiChevronUp } from "react-icons/hi";
import { MdBarChart, MdCached, MdCode, MdSettings } from "react-icons/md";

import { useSignOut } from "@/hooks/useSignOut";

import type { BoxProps, FlexProps } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import type { IconType } from "react-icons";

interface NavItemProps extends FlexProps {
  href: string;
  icon: IconType;
  isActive: boolean;
}

function NavItem({
  icon,
  children,
  href,
  isActive,
}: PropsWithChildren<NavItemProps>) {
  return (
    <NextLink href={href} passHref>
      <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
        <HStack
          borderRadius="lg"
          data-selected={isActive ? "" : undefined}
          _selected={{ bg: "gray.200" }}
          role="group"
          cursor="pointer"
          mx={6}
          p={3}
          _hover={{
            bg: "gray.200",
          }}
          spacing={4}
        >
          {icon && <Icon fontSize="20" as={icon} />}
          <Box w="full">{children}</Box>
        </HStack>
      </Link>
    </NextLink>
  );
}

interface LinkItemProps {
  name: string;
  href: string;
  icon: IconType;
}
const linkItems: Array<LinkItemProps> = [
  { name: "Integrations", href: "/integrations", icon: MdCode },
  { name: "Performance", href: "/performance", icon: MdBarChart },
  { name: "Financing", href: "/financing", icon: MdCached },
  { name: "Account", href: "/account", icon: MdSettings },
];

export function Sidebar(props: BoxProps) {
  const { user } = useUser();
  const { signOut } = useSignOut();
  const router = useRouter();

  return (
    <Flex
      as="aside"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      align="start"
      direction="column"
      justify="space-between"
      w={60}
      pos="fixed"
      py={2}
      h="full"
      {...props}
    >
      <Box w="full">
        <Image
          mb={6}
          mt={4}
          ml={6}
          alt="Loan Data"
          src="/images/level_logo_full.svg"
          height="50"
        />
        <Stack>
          {linkItems.map((link) => (
            <NavItem
              key={link.name}
              href={link.href}
              icon={link.icon}
              isActive={router.pathname.startsWith(link.href)}
            >
              <Text color="ButtonText" fontSize="lg">
                {link.name}
              </Text>
            </NavItem>
          ))}
        </Stack>
      </Box>

      <Flex justify="center" w="full">
        <Menu>
          <MenuButton
            as={Button}
            leftIcon={<FaUserCircle />}
            rightIcon={<HiChevronUp />}
            color="black"
            variant="ghost"
            justifyContent="flex-start"
          >
            <Text isTruncated maxWidth={60}>
              {user.primaryEmailAddress.emailAddress}
            </Text>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
