import { btoa } from "@/lib/utils";

export const HTTP_METHODS = {
  DELETE: "DELETE",
  GET: "GET",
  PATCH: "PATCH",
  POST: "POST",
};

export type HTTP_METHODS = typeof HTTP_METHODS[keyof typeof HTTP_METHODS];

export interface BasicAuthCredentials {
  username: string;
  password: string;
}

export function basicAuth(credentials: BasicAuthCredentials) {
  const { username = "", password = "" } = credentials;
  return `Basic ${btoa(`${username}:${password}`)}`;
}

class FetchError extends Error {
  info?: Record<string, any>;
  status?: number;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const error = new FetchError("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
}

export const http = {
  delete<T = unknown>(input: RequestInfo, init: RequestInit): Promise<T> {
    const { headers, ...rest } = init;

    return fetcher(input, {
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      method: HTTP_METHODS.DELETE,
      ...rest,
    });
  },

  get<T = unknown>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return fetcher(input, init);
  },

  patch<T = unknown>(
    input: RequestInfo,
    data: any,
    init: RequestInit = {}
  ): Promise<T> {
    const { headers, ...rest } = init;

    return fetcher(input, {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      method: HTTP_METHODS.PATCH,
      ...rest,
    });
  },

  post<T = unknown>(
    input: RequestInfo,
    data: any,
    init: RequestInit = {}
  ): Promise<T> {
    const { headers, ...rest } = init;

    return fetcher(input, {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      method: HTTP_METHODS.POST,
      ...rest,
    });
  },
};
