import React from "react";

import { Box, useColorModeValue } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import { ErrorFallback } from "@/components/ErrorFallback";
import { Sidebar } from "@/components/Sidebar";

import type { PropsWithChildren, ReactElement } from "react";

export function DashboardLayout(
  props: PropsWithChildren<{ pageHeader: ReactElement }>
) {
  return (
    <Box h="100vh" bg={useColorModeValue("white", "gray.900")}>
      <Sidebar />

      <Box ml={60} p={4}>
        <Sentry.ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "DashboardLayout");
          }}
          fallback={ErrorFallback}
        >
          {props.pageHeader}

          <Box as="main" px={12} py={8}>
            {props.children}
          </Box>
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  );
}
